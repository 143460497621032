import React, { useContext, useEffect, useMemo, useState } from "react";
// import { parseLinkHeader } from "@web3-storage/parse-link-header";
import ErrorHeader from "../error-header";

export const CinemaModeContext = React.createContext(null);

export function CinemaModeProvider({ children }) {
  const [cinemaMode, setCinemaMode] = useState(
    () => localStorage.getItem("cinema-mode") === "true"
  );

  const state = useMemo(
    () => ({
      cinemaMode,
      setCinemaMode,
      toggleCinemaMode: () => setCinemaMode((prev) => !prev),
    }),
    [cinemaMode, setCinemaMode]
  );

  useEffect(
    () => localStorage.setItem("cinema-mode", cinemaMode),
    [cinemaMode]
  );
  return (
    <CinemaModeContext.Provider value={state}>
      {children}
    </CinemaModeContext.Provider>
  );
}

export function PlayerPage() {
  const { cinemaMode, toggleCinemaMode } = useContext(CinemaModeContext);
  const [peerConnectionDisconnected, setPeerConnectionDisconnected] =
    React.useState(false);

  return (
    <>
      {peerConnectionDisconnected && (
        <ErrorHeader>
          {" "}
          WebRTC has disconnected or failed to connect at all 😭{" "}
        </ErrorHeader>
      )}
      <div
        className={`flex flex-col items-center ${
          !cinemaMode && "mx-auto px-2 py-2 container"
        }`}
      >
        <Player
          cinemaMode={cinemaMode}
          // peerConnectionDisconnected={peerConnectionDisconnected}
          // setPeerConnectionDisconnected={setPeerConnectionDisconnected}
        />
        <button
          className="bg-blue-900 px-4 py-2 rounded-lg mt-6"
          onClick={toggleCinemaMode}
        >
          {cinemaMode ? "Disable cinema mode" : "Enable cinema mode"}
        </button>
      </div>
    </>
  );
}

export const Player = React.memo(
  ({ streamId, style }) => {
    return (
      <iframe
        src={"https://swiss2.streamset.pro/" + streamId}
        scrolling="no"
        title="streamId"
        allowFullScreen={true}
        style={{
          width: "100%",
          aspectRatio: 16 / 9,
          border: "none",
          padding: "none",
          ...style,
        }}
      ></iframe>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.streamId === nextProps.streamId &&
      prevProps.style.transform === nextProps.style.transform
    );
  }
);
